window.trackEvent = function (category, action, label, value, success) {
    if ($("#sessionDebugger").length > 0) { // simple debug mode check
        console.log(
          "track Event",
          "category:" + category,
          "action:" + action,
          "label:" + label,
          "value:" + value
        );
    }
    if (!success) {
        success = function() {};
    }

    triggerAttachedEvents(category, action, label, value, success);

    if (typeof gtag !== "undefined") {
        gtag('event', action, {
            'event_category': category,
            'event_label': label,
            'value': value
        });

        if (action.indexOf("show-overlay-inbound-") !== -1) {
            gtag('event', 'show-overlay-inbound-overlay', {
                'event_category': category,
                'event_label': label,
                'value': value
            });
        }
    }
    else {
        window.events = window.events || [];
        window.events.push([category, action, label, value]);
        if (action.indexOf("show-overlay-inbound-") !== -1) {
            window.events.push([category, "show-overlay-inbound-overlay", label, value]);
        }
    }

    if (typeof _paq !== "undefined") {
        // all tracked events are changed for matomo name(label) <=> action
        // _paq.push(['trackEvent', 'Event Category', 'Event Action', 'Event Name', 'Event Value']);
        _paq.push(['trackEvent', category, label, action, value]);
    }

    $.ajax("/track/" + category + "/" + action + "/" + label + "/" + encodeURIComponent(value), { success: function() {
        success();
    }});
}

$(function() {
  setTimeout(() => {
    if (typeof gtag !== "undefined" && window.events) {
      for (var i in window.events) {
          var event = window.events[i];
          gtag('event', event[1], {
              'event_category': event[0],
              'event_label': event[2],
              'value': event[3]
          });
      }
    }
  }, 500)
});

triggerAttachedEvents = function(category, action, label, value, success) {
  if (category === 'form-consultation-form' && action === 'submit') {
    window.trackEvent(category, 'BeratungsgesprächAnmeldung', label, value, success);
  }
  if (category === 'form-info-event-form' && action === 'submit') {
    window.trackEvent(category, 'InfoanlassAnmeldung', label, value, success);
  }
  if (category === 'form-course-form' && action === 'submit') {
    window.trackEvent(category, 'LehrgangAnmeldung', label, value, success);
  }
  if (category === 'form-overlay-inbound-download-e-book' && action === 'submit') {
    window.trackEvent(category, 'InboundAnmeldung', label, value, success);
  }
}
